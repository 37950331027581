import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import events from '../../data/events'
import { useParams } from 'react-router-dom';
import { MdInfoOutline } from "react-icons/md";
import { FaBullhorn } from 'react-icons/fa';
import UpcomingEvents from '../../components/sections/UpcomingEvents';
import Button from '../../components/Button';
import Faq from '../../components/rj-components/Faq';
import ContactMap from '../../components/ContactMap';
import { AffContext } from '../../contexts/AffiliateContext';
import trackingLink from '../../util/trackingLink';
import GlobalContext from '../../contexts/store';
import getURLParameter from '../../helpers/getURLParameter';
import { Get } from '../../api/server';
import TicketCart from '../../components/TicketCart';
import usdFormatter from '../../helpers/usdFormatter';
import analytics from '../../util/analytics-ag';
import ticketPriceMinMax from '../../helpers/ticketPriceMinMax';
import moment from 'moment';

const stripHtml = (html) => {
  if (!html) return '';

  return html
    // First remove all HTML tags except <p> and <br>
    // .replace(/<(?!\/?p|\/)[^>]+>/g, '')
    // Clean up any style or class attributes
    .replace(/\s*style="[^"]*"/g, '')
    .replace(/\s*class="[^"]*"/g, '')
    // Replace HTML entities
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .trim();
};


function Event() {
  const { aff } = useContext(AffContext);
  const { slug } = useParams();
  const { company } = useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageData, setPageData] = useState({});
  const [ticketQuantities, setTicketQuantities] = useState({});
  const [displayTicketPrices, setDisplayTicketPrices] = useState({}); // minPrice, maxPrice
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [affId, setAffId] = useState(null);
  const [eventLink, setEventLink] = useState('');
  const [didAff, setDidAff] = useState(false);
  const [showFullDetails, setShowFullDetails] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    analytics();
    async function getData() {
      try {
        const response = await Get(`/events/getEvent/${slug}`);
        if (response) {
          setPageData(response);
          setCurrentTicket(response);
          setDisplayTicketPrices(ticketPriceMinMax(response?.ticketinventory?.filter(ticket => !moment(ticket.endDate).isBefore(moment())) || []));
          const link = getURLParameter('aff');
          let mainlink = response?.ticket_link;
          let newlink;
          if (link) {
            let newlink = mainlink + '?aff=' + link;
            setEventLink(newlink);
          } else {
            newlink = '?aff=Website';
            setEventLink(newlink);
          }
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    getData();
  }, [slug, aff, pageData?._id]);



  useEffect(() => {
    async function sendData() {
      try {
        if (aff && pageData?._id) {
          await trackingLink(pageData?._id, aff).then(async (res) => await setAffId(res?._id || null));
          setDidAff(true);
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    sendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aff, pageData]);

  useEffect(() => {
    document.body.style.backgroundColor = '#101010';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <>
      <div className={`bg-[#101010] mt-[80px] ${isMobile ? 'px-5' : 'px-[190px]'} flex flex-col justify-center`}>
        <Row className='my-5'>
          <Col md={8} className={`order-2 order-md-1 ${isMobile ? 'mt-5' : ''}`}>
            <Row>
              <Col sm={12}>
                <h1 className='text-[48px] md:text-[64px] font-bold text-white'>{pageData?.name}</h1>
              </Col>
              <Col sm={12} className='pb-5'>
                <div className='text-[20px] font-bold text-white'>{moment(pageData?.start_date).format('ddd, MMM D, hA')} {moment(pageData?.start_date).format('YYYY')}</div> <br />
                <div className='text-[16px] font-weight-normal mt-[-10px] text-white '>{company?.name}</div> <br />
                <div className='text-[16px] font-weight-normal mt-[-20px] text-white'>{pageData?.organization?.address + ','} {pageData?.organization?.city + ','} {pageData?.organization?.state + ','} {pageData?.organization?.zip}</div>
                <h3 className='text-[32px] font-bold mt-5 text-white'>About</h3>
                <div className='event-details'>
                  {showFullDetails ? (
                    <div className='text-[16px] event-details text-white' dangerouslySetInnerHTML={{ __html: stripHtml(pageData?.details) }} />
                  ) : (
                    <div className='text-[16px] event-details text-white' dangerouslySetInnerHTML={{ __html: stripHtml(pageData?.details?.slice(0, 700) + '...') }} />
                  )}
                </div>
                {pageData?.details?.length > 700 && (
                  <div
                    className='text-[23px] font-bold cursor-pointer mt-3 text-white'
                    onClick={() => setShowFullDetails(!showFullDetails)}
                  >
                    {showFullDetails ? 'Show Less' : 'Read More'}
                  </div>
                )}

                <div className='flex items-center gap-2 mt-5'>
                  <span><MdInfoOutline size={20} color='white' /></span>
                  <span className='text-[20px] text-white'>This is a {pageData?.age} event</span>
                </div>
                <div className='flex items-center gap-2 mt-2'>
                  <span><FaBullhorn size={20} color='white' /></span>
                  <span className='text-[20px] text-white'>{pageData?.organization?.name}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4} className='flex justify-center order-1 order-md-2 mb-4 mb-md-0'>
            <div className='sticky top-[100px]'>
              <Row>
                <Col className='flex justify-center' sm={12}>
                  <img
                    src={pageData?.promo?.story || pageData?.promo?.post}
                    className='w-[400px] md:w-[340px] object-cover rounded-lg'
                    alt={pageData?.name} />
                </Col>
                <Col className='flex justify-center' sm={12}>
                  <div className='flex flex-col items-center justify-center bg-black rounded-lg p-3 w-[400px] md:w-[340px] mt-4 gap-2 bg-white'>
                    {pageData?.ticketinventory?.length > 0 ? (
                      <>
                        <div className='text-sm text-black font-bold'>Starting at</div>
                        <div className='text-lg font-bold text-black'>{usdFormatter(displayTicketPrices.minPrice)} - {usdFormatter(displayTicketPrices.maxPrice)}</div>
                        <TicketCart
                          show={show}
                          event={pageData}
                          handleClose={handleClose}
                          handleShow={handleShow}
                          currentTicket={currentTicket}
                          company={company}
                          ticketQuantities={ticketQuantities}
                          setTicketQuantities={setTicketQuantities}
                          affId={affId}
                        />
                      </>
                    ) : (
                      <div className='text-sm text-black font-bold'>No Available Tickets</div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* <Row className='mt-5 bg-[#101010]'>
          <Col sm={12} className=''>
            <ContactMap zoom={15} height={isMobile ? 300 : 500} width={isMobile ? null : '100%'} />
          </Col>
        </Row> */}
      </div>
      <div className={`bg-[#101010]`}>
        <UpcomingEvents limit={3} isDark={true} excludeEvents={[pageData?._id]} />
        <Faq isDark={true} />
      </div>
    </>
  )
}

export default Event

