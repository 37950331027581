import { React, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

const faqData = [
  {
    question: "Will there be a line to get in?",
    answer:
      "It depends on the event, but typically, entry is quick if you arrive early.",
  },
  {
    question: "Can I buy tickets online?",
    answer:
      "Yes, you're in luck. This site is powered by Live On Beat which means you can purchase tickets directly here on the website with ease. You can check out with Apple pay, Google Pay, Credit Card. The convenience of the Live On Beat system is top of the line and you can rest assured all of your personal information will never be sold or given to anyone. We do not keep records of credit card logins.",
  },
  {
    question: "Who's headlining the club?",
    answer: "Check our events page for updated information on headliners.",
  },
  {
    question: "Can I get a refund?",
    answer:
      "Refunds are available for certain events. Please check the refund policy for more details.",
  },
];

export default function Faq({ id, isDark = false }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div id={id} className={`${isMobile ? 'px-10' : 'px-[80px]'} pb-[100px] pt-[50px]`}>
      <h1 style={{ fontWeight: 900 }} className={`text-center text-4xl font-semibold mb-4 ${isDark ? 'text-white' : ''}`}>
        FAQ
      </h1>
      <div className="flex flex-col items-center">
        {faqData.map((faq, index) => (
          <div key={index} className="border-b w-full max-w-[1240px] mb-2">
            <div
              className="flex justify-between items-center py-4 cursor-pointer"
              onClick={() => toggleFaq(index)}
            >
              <h4 className={`text-sm md:text-base ${isDark ? 'text-white' : 'text-black'}`}>{faq.question}</h4>
              {openFaq === index ? (
                <IoIosArrowDown color={isDark ? 'white' : 'black'} className="font-bold w-4 h-4 md:w-6 md:h-6" />
              ) : (
                <IoIosArrowForward color={isDark ? 'white' : 'black'} className="font-bold w-4 h-4 md:w-6 md:h-6" />
              )}
            </div>
            {openFaq === index && (
              <p className={`text-sm md:text-base mb-4 ${isDark ? 'text-white' : 'text-black'}`}>{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
