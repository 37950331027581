// <ApplePay
//   ticketQuantities={ticketQuantities}
//   totalAmountDue={totalAmountDue}
//   event={event}
//   handleClose={handleClose}
// />

import React, { useContext, useEffect, useState } from 'react';
import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';
import GlobalContext from '../contexts/store';
import { Post } from '../api/server';
import { useNavigate } from 'react-router-dom';

const ApplePay = ({ ticketQuantities, totalAmountDue, event, currentTicket, handleClose, history, affId, terms }) => {

  const { company } = useContext(GlobalContext);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    if (totalAmountDue < 1 || typeof totalAmountDue === "undefined") {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: event?.name || 'Ticket(s)',
        amount: Math.round(totalAmountDue * 100)
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        setIsApplePayAvailable(result.applePay);
        setIsGooglePayAvailable(result.googlePay);
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e) => {
      if (!terms) {
        e.complete('fail');
        // toast.error('You must accept the terms and conditions before proceeding with the payment.');
        return;
      }
      Post(`/events/buyTickets/${currentTicket._id}`, {
        token: e.paymentMethod,
        company: company._id,
        first_name: e.payerName.split(' ')[0],
        last_name: e.payerName.split(' ')[1],
        phone: e.payerPhone,
        email: e.payerEmail,
        tickets: ticketQuantities,
        amountDue: totalAmountDue?.toFixed(2),
        affId: affId
      }).then(async res => {
        if (res.paymentIntent.status === 'requires_action') {
          stripe.confirmCardPayment(res.paymentIntent.client_secret, {
            payment_method: e.paymentMethod.id
          });
        }
        e.complete('success');
        // toast.success('Ticket(s) has been purchased successfully');
        navigate(`/events/confirmation/${res.user}/${res.event}`)
        handleClose();
      }).fail(err => {
        console.log(err);
        e.complete('fail');
        // toast.error(err?.responseJSON?.payment_intent?.last_payment_error?.message || 'Something went wrong on our end. Ticket(s) has not been purchased. Please try again or contact support.');
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, company, ticketQuantities, totalAmountDue, event, currentTicket._id,]);

  return (
    <>
      {paymentRequest && (
        <>
          {(isGooglePayAvailable || isApplePayAvailable) ?  (
            <center>
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            </center>
          ) : null}
        </>
      )}
    </>
  );
};

export default ApplePay;