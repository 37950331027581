import React, { useEffect } from "react";
import GalleryItem from "../../components/rj-components/GalleryItem.js";

function Gallery() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <div>Gallery (The single gallery for a past event)</div>;
}

export default Gallery;
