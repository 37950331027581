import React, { useContext, useState } from "react";
import SocialMediaWrapper from "../rj-components/SocialMediaWrapper";
import SiteButton from "../rj-components/SiteButton";
import moment from "moment";
import { Link } from "react-router-dom";
import CompanyLogo from "../../layouts/CompanyLogo";
import GlobalContext from "../../contexts/store";
import { toast } from "react-toastify";
import { Post } from "../../api/server";

function RJFooter() {
  const { company } = useContext(GlobalContext);
  const [focused, setFocused] = useState(false);

  const [values, setValues] = useState({
    email: "",
  });

  const resetForm = () => {
    setValues({
      email: "",
    });
  };

  const handleFocused = (e) => {
    setFocused(true);
  };

  const notify = (data, type) => {
    toast(data, {
      type: type || "default",
      theme: "dark",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    Post("/users", {
      email: values.email,
      company: company._id,
    })
      .then(() => {
        resetForm();
        notify("Thank you for subscribing!", "success");
      })
      .fail((err) => {
        resetForm();
        notify(err.responseJSON, "error");
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <footer className="flex justify-center items-center pb-16 bg-black">
      <div className="flex flex-col  w-full mt-4 px-[5%]">
        <div className="flex flex-col md:flex-row justify-around w-full border-b pb-[60px] border-gray-400 space-y-8 md:space-y-0 md:space-x-8">
          <div className="flex flex-col items-center gap-4">
            <CompanyLogo isDark={false} />
            {/* <SocialMediaWrapper /> */}
          </div>

          <div className="flex flex-col items-center md:items-start">
            <h5 className="font-bold text-white">TABLES</h5>
            <p className="opacity-60 text-white mb-[-3px]">
              For Reservations Please Contact
            </p>
            <a
              href="mailto:Hello@firstandbell.com"
              className="opacity-60 text-white mb-[-3px] no-underline"
            >
              Hello@firstandbell.com
            </a>
            <br />
            <Link className="no-underline" to={"/terms"}>
              <p className="opacity-60 text-white">Privacy Policy</p>
            </Link>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <h5 className="font-bold text-white">CONTACT</h5>
            <a
              href="mailto:Hello@firstandbell.com"
              className="opacity-60 text-white mb-[-3px] no-underline"
            >
              Hello@firstandbell.com
            </a>
            <a
              href="tel:206-915-9324"
              className="opacity-60 text-white mb-[-3px] no-underline"
            >
              206-915-9324
            </a>
            <br />
            <p className="opacity-60 text-white mb-[-3px]">
              2218 First Avenue,
            </p>
            <p className="opacity-60 text-white mb-[-3px]">Seattle, WA 98121</p>
          </div>

          <div className="flex flex-col items-center md:items-start flex-grow max-w-full md:max-w-[20%]">
            <h5 className="font-bold text-white">SUBSCRIBE</h5>
            <p className="opacity-60 text-white">Email Address</p>
            <input
              className="bg-black border-b border-gray-400 outline-none text-white mb-2 py-2 w-full placeholder:text-gray-500"
              value={values.email}
              name="email"
              placeholder="Enter your email"
              onChange={onChange}
              onBlur={handleFocused}
            />
            <SiteButton buttonText={"Subscribe"} onClick={handleSubmit} />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-white mt-4">
            © {moment().year()} First & Bell
          </span>
        </div>
      </div>
    </footer>
  );
}

export default RJFooter;
