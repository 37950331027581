import React, { useEffect, useState } from "react";
import DarkGradient from "../DarkGradient";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import headerBg from "../../assets/images/headers/4.jpg";
import LOGOLIGHT from "../../assets/logos/logoLight.svg";

import AnimatedText from "../rj-components/AnimatedText";
import AnimatedBlock from "../rj-components/AnimatedBlock";

import { Col, Row } from "react-bootstrap";

function LandingHeader() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative justify-center items-center h-screen w-full overflow-hidden max-h-[670px]">
      <img
        src={headerBg}
        alt="Header Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      />
      <DarkGradient />
      <div className="relative z-10">
        <Row>
          <Col
            md={12}
            className="h-[720px] flex flex-col items-center justify-center"
          >
            {/* <h1 className={`text-white ${isMobile ? 'text-[3vw]' : 'text-[1.5vw]'} font-bold mb-2 [text-shadow:0px_4px_4.2px_#0000008C]`}>FIRST & BELL</h1> */}
            {/* <h1 className={`text-white ${isMobile ? 'text-[8vw]' : 'text-[6vw]'} font-bold mb-2 [text-shadow:0px_4px_4.2px_#0000008C]`}>DOWNTOWN SEATTLE'S</h1> */}
            <AnimatedBlock
              children={
                <div className="flex flex-col justify-center items-center">
                  <img
                    className={`w-auto ${
                      isMobile ? "h-[10vh]" : "h-[20vh]"
                    } px-[2px]`}
                    src={LOGOLIGHT}
                    alt="Your Company"
                  />
                  <h1
                    className={`text-white text-center ${
                      isMobile ? "text-[4vw]" : "text-[2.5vw]"
                    } font-bold ${
                      isMobile ? "mt-[30px]" : "mt-[75px]"
                    } mb-4 [text-shadow:0px_4px_4.2px_#0000008C]`}
                  >
                    DOWNTOWN SEATTLE'S PREMIER URBAN EVENT SPACE
                  </h1>
                </div>
              }
            />
            {/* <img
              className={`w-auto ${isMobile ? "h-[10vh]" : "h-[20vh]"}`}
              src={LOGOLIGHT}
              alt="Your Company"
            /> */}
            {/* <h1
              className={`text-white ${
                isMobile ? "text-[4vw]" : "text-[2.5vw]"
              } font-bold mt-[75px] mb-4 [text-shadow:0px_4px_4.2px_#0000008C]`}
            >
              DOWNTOWN SEATTLE'S PREMIER URBAN EVENT SPACE
            </h1> */}
            {/* <span className={`text-white ${isMobile ? 'text-[3vw]' : 'text-[1.5vw]'} [text-shadow:0px_4px_4.2px_#0000008C]`}>
              The perfect urban venue for your next corporate or social event!
            </span> */}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LandingHeader;
