'use client';

import { createContext } from 'react';

const GlobalContext = createContext({
  company: {},
  setcompany: () => {},

  events: [],
  setEvents: () => {},

  organizations: [],
  setOrganizations: () => {},
});

export default GlobalContext;
