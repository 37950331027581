import React, { useEffect, useState, useRef } from "react";
import Button from "./Button";
import LightGallery from "lightgallery/react";

// import styles
// import 'lightgallery/css/lightgallery.css';
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

import lgShare from "lightgallery/plugins/share";
import "lightgallery/css/lg-share.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import images from "../data/images";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AnimatedText from "./rj-components/AnimatedText";
import AnimatedBlock from "./rj-components/AnimatedBlock";
import "../styles/gallery.css";

function CreateMemories() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row
      className={`bg-black ${
        isMobile ? "p-5" : "p-[90px]"
      } bg-gray-100 min-h-[900px] mx-0`}
    >
      <div
        className={`flex ${
          isMobile ? "flex-col gap-5" : "items-center justify-between"
        }`}
      >
        {/* <h1 className="text-7xl text-white">CREATE MEMORIES</h1> */}
        <AnimatedText
          text={"CREATE MEMORIES"}
          className="text-7xl text-white"
        />
        <AnimatedBlock
          children={
            <button
              onClick={() => navigate("/contact")}
              className=" bg-white text-black border-1 border-black px-[35px] w-fit py-[12px] font-medium text-sm rounded-[5px] flex items-center justify-center gap-4"
            >
              Rent Space
            </button>
          }
        />
      </div>
      <AnimatedBlock
        children={
          <p className="text-white text-[16px] mt-4">
            Our customizable layout with three distinct spaces can be used to
            accommodate up to 500 cocktail-style or 120 for sit-down dinners.
          </p>
        }
      />
      <div className="grid-gallery mt-10">
        <LightGallery
          onInit={null}
          speed={500}
          plugins={[lgThumbnail, lgZoom, lgShare]}
          share={{
            facebook: true,
            twitter: true,
            instagram: true,
          }}
          elementClassNames="grid-gallery-inner"
        >
          {images.length > 0 ? (
            <>
              {images.map((item, key) => (
                <a
                  className={`gallery-item span-${(key % 3) + 1}`}
                  key={key}
                  href={item}
                >
                  <img
                    alt="Create Memories"
                    src={item}
                    className="w-full h-full object-cover"
                  />
                </a>
              ))}
            </>
          ) : null}
        </LightGallery>
      </div>
    </Row>
  );
}

export default CreateMemories;
