import React, { useEffect } from "react";
import BasicHeader from "../../components/headers/BasicHeader";
import { Col, Container, Row } from "react-bootstrap";
import headerBg from "../../assets/images/headers/4.jpg";
import GalleryItem from "../../components/rj-components/GalleryItem.js";

import e1 from "../../assets/images/events/Rectangle12.png";
import e2 from "../../assets/images/events/Rectangle13.png";
import e3 from "../../assets/images/events/Rectangle14.png";

const upcomingEvents = [
  {
    id: 1,
    eventName: "HENTAI ME UP!",
    eventDescription:
      "Anime theme rave event hosted by Magnetic Media Events | 21+",
    eventImage: e1,
    eventDate: "2024-12-01",
  },
  {
    id: 2,
    eventName: "Bane's Night Out",
    eventDescription:
      "Dive into the darkness with Bane. An exclusive villain-themed party | 18+",
    eventImage: e2,
    eventDate: "2024-11-25",
  },
  {
    id: 3,
    eventName: "Poison Ivy's Green Gala",
    eventDescription:
      "A botanical-themed evening inspired by the legendary Poison Ivy | Formal Attire Required",
    eventImage: e3,
    eventDate: "2024-12-10",
  },
  {
    id: 4,
    eventName: "Flyer Design Workshop",
    eventDescription:
      "Learn the art of graphic design with a hands-on flyer creation session | All skill levels welcome",
    eventImage: e2,
    eventDate: "2024-12-03",
  },
  {
    id: 5,
    eventName: "Summoning Shenron",
    eventDescription:
      "A Dragon Ball Z fan meet-up featuring games, trivia, and cosplay | All Ages",
    eventImage: e1,
    eventDate: "2024-12-15",
  },
  {
    id: 6,
    eventName: "The Grinch",
    eventDescription:
      "Watchparty movie event for the grinch stealing Christmas | 25+",
    eventImage: e1,
    eventDate: "2024-12-01",
  },
  {
    id: 7,
    eventName: "Bane's Night Out",
    eventDescription:
      "Dive into the darkness with Bane. An exclusive villain-themed party | 18+",
    eventImage: e2,
    eventDate: "2024-11-25",
  },
  {
    id: 8,
    eventName: "Poison Ivy's Green Gala",
    eventDescription:
      "A botanical-themed evening inspired by the legendary Poison Ivy | Formal Attire Required",
    eventImage: e3,
    eventDate: "2024-12-10",
  },
  {
    id: 9,
    eventName: "Flyer Design Workshop",
    eventDescription:
      "Learn the art of graphic design with a hands-on flyer creation session | All skill levels welcome",
    eventImage: e2,
    eventDate: "2024-12-03",
  },
  {
    id: 10,
    eventName: "Summoning Shenron",
    eventDescription:
      "A Dragon Ball Z fan meet-up featuring games, trivia, and cosplay | All Ages",
    eventImage: e1,
    eventDate: "2024-12-15",
  },
  {
    id: 11,
    eventName: "Flyer Design Workshop",
    eventDescription:
      "Learn the art of graphic design with a hands-on flyer creation session | All skill levels welcome",
    eventImage: e2,
    eventDate: "2024-12-03",
  },
  {
    id: 12,
    eventName: "Summoning Shenron",
    eventDescription:
      "A Dragon Ball Z fan meet-up featuring games, trivia, and cosplay | All Ages",
    eventImage: e1,
    eventDate: "2024-12-15",
  },
];

function GalleryList() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <BasicHeader title="Gallery" image={headerBg} />
      <div className="bg-white flex flex-col justify-center items-center pt-[74px] pb-[162px]">
        {upcomingEvents
          .reduce((rows, event, index) => {
            if (index % 3 === 0) rows.push([]); // Start a new row every 3 events
            rows[rows.length - 1].push(event); // Add event to the current row
            return rows;
          }, [])
          .map((row, rowIndex) => (
            <Row key={rowIndex} className="md:mb-[90px]">
              {row.map((event) => (
                <Col
                  key={event.id}
                  lg={4}
                  className="d-flex justify-content-center px-0"
                >
                  <GalleryItem
                    eventImage={event.eventImage}
                    eventDate={event.eventDate}
                    eventName={event.eventName}
                  />
                </Col>
              ))}
            </Row>
          ))}
        <button class="border-1 border-black px-[50px] py-3 font-medium text-sm rounded-[5px]">
          Load More
        </button>
      </div>
    </div>
  );
}

export default GalleryList;
