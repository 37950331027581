/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useState, useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import routes from "../routes/menuRoutes";
import { IoMenu, IoClose } from "react-icons/io5";
import SiteButton from "../components/rj-components/SiteButton";
import CompanyLogo from "./CompanyLogo";
import GlobalContext from "../contexts/store";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { events } = useContext(GlobalContext);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeEvents, setActiveEvents] = useState([]);

  useEffect(() => {
    const activeEvents = events.filter((event) => event.active === true);
    setActiveEvents(activeEvents);
  }, [events]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getVisibleRoutes = (routes) => {
    return routes.filter((route) => {
      if (route?.path === "/events") return activeEvents.length > 0;
      if (route?.path === "/galleries") return false;
      return true;
    });
  };

  return (
    <Disclosure as="nav" className={`top-0 fixed bg-black w-full z-50`}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-6xl md:max-w-7xl px-6 py-2 md:px-0 lg:px-8 bg-transparent">
            <div className="relative flex h-16 items-center justify-between bg-transparent">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden bg-transparent">
                {/* Mobile menu button*/}
                <Disclosure.Button className="p-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <IoClose size={20} color="white" />
                  ) : (
                    <IoMenu size={20} color="white" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center  sm:justify-between">
                <CompanyLogo size={30} hiddenOnMobile={true} />
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4 justify-center items-center">
                    {getVisibleRoutes(routes).map((item) => (
                      <Link
                        key={item.name}
                        to={item.path}
                        className={classNames(
                          "relative px-3 py-2 text-sm font-medium no-underline rounded-none",
                          isActive(item.path)
                            ? "text-white after:w-full"
                            : "text-gray-300 hover:text-gray-400 hover:after:w-full after:w-0",
                          "after:absolute after:bottom-0 after:left-0 after:h-[1px] after:bg-gray-300",
                          "after:transition-all after:duration-300 after:ease-in-out"
                        )}
                        aria-current={isActive(item.path) ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Buttons can go here! */}
                <SiteButton
                  buttonText={"Rent Space"}
                  as={Link}
                  to={"/contact"}
                  className="max-w-[171px] text-small py-[13px] px-[30px] max-h-[40px]"
                />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {getVisibleRoutes(routes).map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.path}
                  className={classNames(
                    isActive(item.path)
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium no-underline"
                  )}
                  aria-current={isActive(item.path) ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
