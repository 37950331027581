import React, { useEffect, useRef } from "react";
import "animate.css";

const AnimatedBlock = ({
  children,
  className = "",
  animation = "fadeInUp",
  delay = 0,
  as: Component = "div",
}) => {
  const blockRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(`animate__${animation}`);
            entry.target.style.opacity = 1;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (blockRef.current) {
      observer.observe(blockRef.current);
    }

    return () => observer.disconnect();
  }, [animation]);

  return (
    <Component
      ref={blockRef}
      className={`animate__animated ${className}`}
      style={{
        opacity: 0,
        animationDelay: `${delay}s`,
      }}
    >
      {children}
    </Component>
  );
};

export default AnimatedBlock;
