import React, { useContext, useEffect, useState } from "react";
import { Post } from "../../api/server";
import GlobalContext from "../../contexts/store";
import { toast } from "react-toastify";
import analytics from "../../util/analytics-ag";

export default function ContactForm() {
  const { company } = useContext(GlobalContext)
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    analytics();
  }, []);

  const resetForm = () => {
    setValues({
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  const inputs = [{
    id: 1,
    name: 'name',
    type: 'text',
    label: 'Your Name',
    placeholder: 'Your Name',
    size: '6',
    errorMessage: 'Please input your name',
    required: true
  },
  {
    id: 3,
    name: 'phone',
    type: 'phone',
    label: 'Phone',
    placeholder: 'Phone',
    size: '6',
    errorMessage: 'Please input your phone number so we can get incontact with you.',
    required: true
  },
  {
    id: 4,
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Email',
    size: '6',
    errorMessage: 'Please input your email',
    required: true

  },
  {
    id: 5,
    name: 'subject',
    type: 'text',
    label: 'Subject',
    placeholder: 'Subject',
    size: '6'
  },
  {
    id: 6,
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Tell us about your project',
    size: '12',
    errorMessage: 'Please tell us about your project so we can better understand',
    required: true
  }];

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    Post('/contacts', {
      first_name: values.name,
      phone: values.phone,
      email: values.email,
      subject: values.subject,
      message: values.message,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Your message has been sent!', 'success');
    }).catch((error) => {
      notify('Please try again later', 'error');
    })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  
  return (
    <div className="bg-gray-100 p-4 md:p-16">
      <h1 style={{ fontFamily: "dm_sans" }} className="text-[64px] font-bold">
        Get in touch
      </h1>
      <form onSubmit={handleSubmit} className="mt-[32px] space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* First Name */}
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium font-[16px]"
            >
              First Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={values.name}
              onChange={onChange}
              className="mt-1 block w-full bg-[#EDEDED] outline-none focus:outline-none pl-2 h-[34px]"
            />
          </div>

          {/* Phone (replacing Last Name) */}
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={values.phone}
              onChange={onChange}
              className="mt-1 block w-full bg-[#EDEDED] outline-none focus:outline-none pl-2 h-[34px]"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={values.email}
              onChange={onChange}
              className="mt-1 block w-full bg-[#EDEDED] outline-none focus:outline-none pl-2 h-[34px]"
            />
          </div>
          {/* Subject */}
          <div>
            <label
              htmlFor="subject"
              className="block text-sm font-medium text-gray-700"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={values.subject}
              onChange={onChange}
              className="mt-1 block w-full bg-[#EDEDED] outline-none focus:outline-none pl-2 h-[34px]"
            />
          </div>
        </div>

        {/* Message */}
        <div>
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={values.message}
            onChange={onChange}
            rows="4"
            className="mt-1 block w-full bg-[#EDEDED] h-[84px] w-full outline-none focus:outline-none pl-2"
          ></textarea>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className="w-[151px] h-[43px] bg-transparent border-[2px] border-black rounded-md font-semibold font-[16px] flex items-center justify-center"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
