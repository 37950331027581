import React from "react";
import ContactMap from "../ContactMap";

export default function ContactAddress({isMobile}) {
  return (
    <div className="bg-gray-100 flex justify-center items-center flex-col">
       <ContactMap zoom={14} height={isMobile ? 247 : 247} width={isMobile ? 350 : 441} />
      <div className="text-center">
        <p className="text-[16px] mt-[10px]">
          You can contact us directly at <br />
          hello@firstandbell.com or 206-915-9324
        </p>
        <p>
          Our address is <strong>2218 First Avenue, Seattle, WA 98121.</strong>
        </p>
      </div>
    </div>
  );
}
