import React, { useEffect, useState } from "react";
import Faq from "../components/rj-components/Faq";
import ContactForm from "../components/rj-components/ContactForm";
import ContactAddress from "../components/rj-components/ContactAddress";

function Contact() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`bg-gray-100 ${isMobile ? "pt-[100px] px-4" : "p-[90px]"}`}>
      <div className="flex flex-col lg:flex-row items-center justify-center gap-8">
        <div className="w-full lg:w-7/12">
          <ContactForm />
        </div>
        <div className="w-full lg:w-5/12">
          <ContactAddress isMobile={isMobile} />
        </div>
      </div>
      <div className="h-[80px]"></div>
      <Faq />
      <div className="h-[160px]"></div>
    </div>
  );
}

export default Contact;
