import Contact from '../pages/Contact';
import Landing from '../pages/Landing';
import Terms from '../pages/Terms';
import Conformation from '../pages/events/Conformation';
import Event from '../pages/events/Event';
import EventList from '../pages/events/EventList';
import Gallery from '../pages/galleries/Gallery';
import GalleryList from '../pages/galleries/GalleryList';
import Home from '../pages/Home';
const routes = [
  {
    name : 'Home',
    path : '/',
    outside : false,
    exact : true,
    Element: <Home />
  },
  {
    name : 'Home2',
    path : '/home2',
    outside : false,
    exact : true,
    Element: <Landing />
  },
  // {
  //   name : 'Home',
  //   path : '*',
  //   outside : false,
  //   Element: <Home />
  // },
  {
    name : 'Events',
    path : '/events',
    outside : false,
    exact : true,
    Element: <Landing />
  },
  {
    name : 'Event',
    path : '/events/event/:slug',
    outside : false,
    exact : true,
    dynamic: true,
    Element: <Event />
  },
  {
    name: 'Conformation',
    path: '/events/confirmation/:user/:event',
    outside: false,
    exact: true,
    dynamic: true,
    Element: <Conformation />
  },
  {
    name : 'Gallery',
    path : '/galleries',
    outside : false,
    exact : true,
    Element: <GalleryList />
  },
  {
    name : 'Gallery',
    path : '/galleries/:slug',
    outside : false,
    exact : true,
    dynamic: true,
    Element: <Gallery />
  },
  {
    name : 'Contact',
    path : '/contact',
    outside : false,
    exact : true,
    Element: <Contact />
  },
  {
    name: 'Terms',
    path: '/terms',
    outside: false,
    exact: true,
    Element: <Terms />
  }
]

export default routes;