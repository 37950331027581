const menuRoutes = [
  {
    name : 'Home',
    path : '/',
    outside : false,
    exact : true,
  },
  {
    name : 'Gallery',
    path : '/galleries',
    outside : false,
    exact : true,
  },
  {
    name : 'Events',
    path : '/events',
    outside : false,
    exact : true,
  },
  {
    name : 'Contact',
    path : '/contact',
    outside : false,
    exact : true,
  }
];

export default menuRoutes;