import React from "react";
import LOGOLIGHT from "../assets/logos/logoLight.svg";
import LOGODARK from "../assets/logos/logoDark.svg";

function CompanyLogo({ isDark, size = 30, hiddenOnMobile = false }) {
  return (
    <img
      style={{ height: size }}
      className={`w-auto ${hiddenOnMobile ? "hidden sm:block" : ""}`}
      src={isDark ? LOGODARK : LOGOLIGHT}
      alt="Your Company"
    />
  );
}

export default CompanyLogo;
