import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import "./App.css";
import "./assets/styles/main.scss";
import "swiper/css";
import { useEffect, useState } from "react";
import GlobalContext from "./contexts/store";
import { AffContext } from "./contexts/AffiliateContext";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import getCompany from "./services/company";
import { Elements } from "@stripe/react-stripe-js";
import { Outlet, Route, Routes } from "react-router-dom";
import routes from "./routes";
import NavBar from "./layouts/NavBar";
import RJFooter from "./components/footers/RJ-Footer";
import { Container } from "react-bootstrap";
import getURLParameter from "./helpers/getURLParameter";
import analytics from "./util/analytics-ag";
import { Get } from "./api/server";

function App() {
  const [company, setcompany] = useState({});
  const [events, setEvents] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [aff, setAff] = useState("");
  const affContexts = { aff, setAff };
  // eslint-disable-next-line no-unused-vars
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe("pk_live_eHPsjUYyy6mpbgQRFUWoy44x", {
      stripeAccount: "acct_1Qcxe0JzL15keBgX",
    })
  );
  const store = { company, setcompany, events, organizations };

  useEffect(() => {
    async function getInitData() {
      const myCompany = await getCompany();
      setcompany(myCompany);
    }
    getInitData();
  }, []);

  useEffect(() => {
    analytics();
  }, []);

  useEffect(() => {
    const parm = getURLParameter("aff");
    if (parm) {
      setAff(parm);
    }
  }, []);

  useEffect(() => {
    const getEvents = async () => {
      try {
        const [response, organizations] = await Promise.all([
          Get(`/events/byCompany/getUpcomingEvents/${company?._id}`),
          Get(`/organizations/getByCompany/${company?._id}`),
        ]);
        setEvents(response);
        setOrganizations(organizations);
      } catch (error) {
        console.log(error);
      }
    };
    if (company?._id) {
      getEvents();
    }
  }, [company?._id]);

  return (
    <GlobalContext.Provider value={store}>
      <AffContext.Provider value={affContexts}>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<Layout />}>
              {routes.map((route, key) => (
                <Route
                  key={key}
                  path={route.path}
                  element={route.Element}
                  exact={route.exact}
                />
              ))}
            </Route>
          </Routes>
        </Elements>
      </AffContext.Provider>
    </GlobalContext.Provider>
  );
}

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen w-full overflow-x-hidden">
      <NavBar />
      <main className="flex-grow">
        <div className="w-full">
          <Outlet />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            toastClassName="myToast"
          />
        </div>
      </main>
      <RJFooter />
    </div>
  );
};

export default App;
