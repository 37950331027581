import React, { useEffect } from 'react'
import LandingHeader from '../components/headers/LandingHeader'
import UpcomingEvents from '../components/sections/UpcomingEvents'
import ImageAd from '../components/ads/ImageAd'
import Faq from '../components/rj-components/Faq'
import headerBg from '../assets/images/headers/4.jpg'
import FixedMenuBar from '../layouts/FixedMenuBar'

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-[#F3F4F6]'>
      <LandingHeader />
      <div className='hidden md:block'>
        <FixedMenuBar />
      </div>
      <UpcomingEvents id="events" />
      <ImageAd
        id="rent-space"
        image={headerBg}
        title="Create your own event"
        text="Explore our versatile spaces, state-of-the-art resources, and customizable floor plans designed to bring your next big event to life. Let us help you plan an unforgettable experience tailored to your needs."
        buttonLink="/contact"
        buttonText="Rent Space"
      />
      {/* <Partners /> */}
      <Faq id="faq" />
      
    </div>
  )
}

export default Landing