import React, { useEffect, useState } from "react";
import HomeHeader from "../components/headers/HomeHeader";
import UpcomingEvents from "../components/sections/UpcomingEvents";
import ImageAd from "../components/ads/ImageAd";
import Faq from "../components/rj-components/Faq";
import headerBg from "../assets/images/headers/4.jpg";
import FixedMenuBar from "../layouts/FixedMenuBar";
import LOGO from "../assets/logos/iconDark.png";
import { Col, Row } from "react-bootstrap";
import Intro from "../components/Intro";
import Spaces from "../components/Spaces";
import CreateMemories from "../components/CreateMemories";
import FloorPlan from "../components/FloorPlan";
import ThreePhotoSection from "../components/ThreePhotoSection";
import Location from "../components/Location";

import AnimatedText from "../components/rj-components/AnimatedText";
import AnimatedBlock from "../components/rj-components/AnimatedBlock";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <HomeHeader />
      <AnimatedBlock children={<Intro />} />
      <Spaces />
      <CreateMemories />
      <FloorPlan />
      <ThreePhotoSection />
      <Location />
    </>
  );
}

export default Home;
