import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Image1 from "../assets/images/venue/1.jpg";
import Image2 from "../assets/images/venue/10.jpg";
import Image3 from "../assets/images/venue/12.jpg";
import AnimatedText from "./rj-components/AnimatedText";

function ThreePhotoSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row
      className={`bg-white ${
        isMobile ? "p-5" : "p-[150px]"
      } items-center justify-center mx-0`}
    >
      <Col md={4} className="relative mb-5">
        <img
          src={Image1}
          alt="image1"
          className="w-full h-[300px] object-cover brightness-[70%]"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          {/* <h3 className="text-white text-2xl font-bold text-center">
            {"Corporate + Nonprofit".toUpperCase()}
          </h3> */}
          <AnimatedText
            text={"Corporate + Nonprofit"}
            className="text-white text-2xl font-bold text-center"
          />
        </div>
      </Col>
      <Col md={4} className="relative mb-5">
        <img
          src={Image2}
          alt="image2"
          className="w-full h-[300px] object-cover brightness-[70%]"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          {/* <h3 className="text-white text-2xl font-bold text-center">
            {"Live Music + Shows".toUpperCase()}
          </h3> */}
          <AnimatedText
            text={"Live Music + Shows"}
            className="text-white text-2xl font-bold text-center"
            delay={0.5}
          />
        </div>
      </Col>
      <Col md={4} className="relative mb-5">
        <img
          src={Image3}
          alt="image3"
          className="w-full h-[300px] object-cover brightness-[70%]"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          {/* <h3 className="text-white text-2xl font-bold text-center">
            {"Clubs + Popups".toUpperCase()}
          </h3> */}
          <AnimatedText
            text={"Clubs + Popups"}
            className="text-white text-2xl font-bold text-center"
            delay={1}
          />
        </div>
      </Col>
    </Row>
  );
}

export default ThreePhotoSection;
