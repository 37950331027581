import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LOGO from "../assets/logos/iconDark.png";

function Intro() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row
      className={`bg-white p-10 ${isMobile ? "" : "px-[190px] gap-20"} mx-0`}
    >
      <Col md={3} className="items-center justify-center">
        <img
          src={LOGO}
          alt="headerBg"
          className="w-[221px] h-[auto] mx-auto block"
        />
      </Col>
      <Col md={8} className="items-center justify-center">
        <p className="text-[16px]">
          <p className="text-[16px]">
            With nearly 9,000 square feet of newly renovated indoor/outdoor
            space, this unique venue features 20+ foot ceilings, dripping
            chandeliers, exposed brick and timbers, wrought iron railings, and a
            rooftop patio.
          </p>

          <p className="text-[16px]">
            It’s the ideal location for weddings, holiday functions, off-site
            corporate events, team building workshops, product launches,
            fundraisers, networking affairs, private parties, photo/film shoots,
            and more!
          </p>

          <p className="text-[16px]">
            Our customizable layout with three distinct spaces can be used to
            accommodate up to 700 cocktail-style or 350 for sit-down dinners.
          </p>
        </p>
      </Col>
    </Row>
  );
}

export default Intro;
