import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import MEZZANINE from "../assets/images/venue/MEZZANINE.jpg";
import SIDEROOM from "../assets/images/venue/SIDEROOM.jpg";
import ROOFTOP from "../assets/images/venue/16.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import AnimatedText from "./rj-components/AnimatedText";

function Spaces() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth <= 1400
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1400);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row className="bg-white overflow-hidden relative mx-0">
      <Row>
        {/* <h1 className={`${isMobile ? "ml-[30px]" : "ml-[190px]"}`}>
          OUR SPACES
        </h1> */}
        <AnimatedText
          text={"OUR SPACES"}
          className={`${
            isMobile ? "ml-[30px]" : isTablet ? "ml-[100px]" : "ml-[190px]"
          }`}
        />
      </Row>

      <div className="relative">
        <Swiper
          slidesPerView={isMobile ? 1 : isTablet ? 1.5 : 2}
          spaceBetween={isMobile ? 10 : isTablet ? 300 : 0}
          loop
          modules={[Navigation, ...(isMobile ? [Autoplay] : [])]}
          navigation={{
            prevEl: ".prev-button",
            nextEl: ".next-button",
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
        >
          <div className="custom-swiper-navigation absolute top-[25%] w-full z-10">
            <button className="prev-button absolute left-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
              <FaArrowLeft size={15} />
            </button>
            <button className="next-button absolute right-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
              <FaArrowRight size={15} />
            </button>
          </div>

          <SwiperSlide
            className={`${
              isMobile
                ? "p-10 ml-[0px]"
                : isTablet
                ? "ml-[100px]"
                : "ml-[190px]"
            }`}
          >
            <div
              className={`flex flex-col mt-[10px] ${
                isMobile ? "" : isTablet ? "w-[500px]" : "w-[689px]"
              }`}
            >
              <div className="w-[689px] h-[388px] overflow-hidden">
                <img
                  src={MEZZANINE}
                  alt="space"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                />
              </div>
              <h2 className="mt-4">THE GREAT ROOM & MEZZANINE</h2>
              <p className="text-[16px]">
                Our customizable layout with three distinct spaces can be used
                to accommodate up to 700 cocktail-style or 350 for sit-down
                dinners.
              </p>
              <p className="text-[12px] font-bold">
                Capacity: 700 cocktail-style.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={`${isMobile ? "p-10" : ""}`}>
            <div
              className={`flex flex-col mt-[10px] ${
                isMobile ? "" : "w-[689px]"
              }`}
            >
              <div className="w-[689px] h-[388px] overflow-hidden">
                <img
                  src={SIDEROOM}
                  alt="space"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                />
              </div>
              <h2 className="mt-4">THE SIDE ROOM</h2>
              <p className="text-[16px]">
                The Side Room can be used as an extra entertainment space where
                you can have a band or DJ, create a casino, video game arcade,
                karaoke room and much more
              </p>
              <p className="text-[12px] font-bold">
                Capacity: 150 cocktail-style or 120 Seated.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={`${isMobile ? "p-10" : ""}`}>
            <div
              className={`flex flex-col mt-[10px] ${
                isMobile ? "" : "w-[689px]"
              }`}
            >
              <div className="w-[689px] h-[388px] overflow-hidden">
                <img
                  src={ROOFTOP}
                  alt="space"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                />
              </div>
              <h2 className="mt-4">THE ROOFTOP DECK</h2>
              <p className="text-[16px]">
                Above the urban hustle of Belltown, the Rooftop Deck is the
                perfect place for an event under the sun or stars. Whether it's
                a wedding ceremony, cocktail reception, or outdoor movie night,
                your guests will love the fresh air and unique spot above the
                city. The Rooftop Deck is equipped with a full outdoor bar that
                includes a bar sink and refrigeration. For cooler Seattle
                evenings, heaters are available to rent.
              </p>
              <p className="text-[12px] font-bold">
                Capacity: 200 Standing, 120 Seated 2,000 SF.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </Row>
  );
}

export default Spaces;
