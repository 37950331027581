import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

function FixedMenuBar() {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 640;
      setIsFixed(window.scrollY >= scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{
      position: isFixed ? 'fixed' : 'absolute',
      top: isFixed ? '0' : undefined,
      marginTop: isFixed ? '0' : '-80px',
      left: isFixed ? '33%' : '50%',
      transform: 'translateX(-50%)',
      zIndex: 1000,
      backgroundColor: 'black',
      maxWidth: '80vw',
      padding: isFixed ? '18px 100%' : '50px 95px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '5px',
    }} className="hidden md:block">
      {/* <div className="flex items-center">
        <span style={{fontFamily: "goldbold"  }} className='text-white text-[2vw] font-bold'>Calendar</span>
        <div className="h-[50px] w-px bg-white mx-[4vw]" />
      </div> */}
      <a href="#events" className="flex items-center">
        <span style={{fontFamily: "goldbold"}} className='text-white text-[1.5vw] font-bold'>Upcoming</span>
        <div className="h-[50px] w-px bg-white mx-[4vw]" />
      </a>
      <a href="#rent-space" className="flex items-center whitespace-nowrap">
        <span style={{fontFamily: "goldbold"}} className='text-white text-[1.5vw] font-bold'>Rent Space</span>
        <div className="h-[50px] w-px bg-white mx-[4vw]" />
      </a>
      <a href="#faq" className="whitespace-nowrap">
        <span style={{fontFamily: "goldbold"}} className='text-white text-[1.5vw] font-bold'>FAQ</span>
      </a>
    </div>
  )
}

export default FixedMenuBar
