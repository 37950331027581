export default [
  require('../assets/images/venue/2.jpg'),
  require('../assets/images/venue/3.jpg'),
  require('../assets/images/venue/4.jpg'),
  require('../assets/images/venue/5.jpg'),
  // require('../assets/images/venue/6.jpg'),
  require('../assets/images/venue/7.jpg'),
  require('../assets/images/venue/8.jpg'),
  require('../assets/images/venue/9.jpg'),
  require('../assets/images/venue/10.jpg'),
  require('../assets/images/venue/11.jpg'),
  require('../assets/images/venue/12.jpg'),
  require('../assets/images/venue/13.jpg'),
  require('../assets/images/venue/14.jpg'),
  require('../assets/images/venue/15.jpg'),
  require('../assets/images/venue/16.jpg'),
]