import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ContactMap from "./ContactMap";

import AnimatedBlock from "./rj-components/AnimatedBlock";

function Location() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row className={`bg-white pb-20 ${isMobile ? "px-5" : "px-[150px]"} mx-0`}>
      <Col md={6} className="mb-5">
        <AnimatedBlock
          children={
            <>
              {" "}
              <h3 className="text-2xl font-bold">LOCATION</h3>
              <p className="text-sm">
                Conveniently located in the heart of Downtown Seattle, you and
                your guests are within one mile of the city’s most popular
                places:
              </p>
              <ul className="list-disc list-inside">
                <li>WA State Convention Center (0.9 miles)</li>
                <li>Pike Place Market (0.3 miles)</li>
                <li>The Space Needle (0.7 miles)</li>
                <li>Amazon Spheres/South Lake Union (0.6 miles)</li>
                <li>Nordstrom (0.8 miles)</li>
                <li>The Palladian Hotel (0.3 miles)</li>
                <li>The Seattle Art Museum (0.5 miles)</li>
                <li>The Moore Theater (0.3 miles)</li>
                <li>Museum of Pop Culture (0.8 miles)</li>
                <li>Bill & Melinda Gates Foundation (0.8 miles)</li>
              </ul>
            </>
          }
        />
      </Col>
      <Col md={6} className="mb-5">
        <AnimatedBlock
          delay={0.4}
          children={
            <>
              <h3 className="text-2xl font-bold">PARKING</h3>
              <p className="text-sm">
                We are located on First Avenue, between Bell and Blanchard.
                There’s street parking and 4 paid lots within 1 block of our
                venue. If you don’t want your guest to drive, we also offer our
                clients discounts on Uber.
              </p>
            </>
          }
        />
      </Col>
      <Col md={12} className="">
        <ContactMap zoom={12} />
        {/* <AnimatedBlock children={<ContactMap zoom={12} />} /> */}
      </Col>
    </Row>
  );
}

export default Location;
