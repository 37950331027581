import React from 'react'
import { Row, Col } from 'react-bootstrap';
import Button from '../Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function SliderContent({ event }) {
  const navigate = useNavigate();

  return (
    <div className='px-[10%]'>
      <Row>
        <Col md={6} className='mb-0 md:mb-0'>
          <img src={event?.promo?.post || event?.promo?.story} alt="First and Bell" className='h-[300px] md:h-[431px] mx-auto md:mx-0 rounded-lg' />
        </Col>
        <Col md={6} className='flex flex-col justify-center items-center text-center px-20 md:px-10'>
          <h1 style={{fontFamily: "goldbold", fontWeight: 900}} className='text-white text-4xl md:text-7xl font-bold mt-2'>{event?.name}</h1>
          <div className='text-white text-[16px] md:text-[20px]'>{event?.organization?.name} | {event?.organization?.age} | {moment(event?.start_date).format('MMM D, YYYY')} | {event?.organization?.city} {event?.organization?.state}</div>
          <div className='flex items-center gap-4 mt-5'>
            <Button onClick={() => navigate(`/events/event/${event?.slug}`)} text="Get Tickets" outline />
            <Button onClick={() => navigate(`/events/event/${event?.slug}`)} text="Learn More" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SliderContent
