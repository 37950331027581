import React from "react";

function Button({
  text,
  icon: Icon,
  outline = false,
  black = false,
  onClick,
  style,
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick ? onClick : null}
      style={style}
      className={`${black ? "border-1 border-black text-black" : ""} ${
        outline
          ? `bg-white text-black border-1 border-black ${disabled ? "opacity-50 cursor-not-allowed" : ""}`
          : `text-white border-1 border-white ${disabled ? "opacity-50 cursor-not-allowed" : ""}`
      } px-[35px] w-fit py-[12px] font-medium text-sm rounded-[5px] flex items-center justify-center gap-4 whitespace-nowrap ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      {Icon && Icon}
      {text || "Load More"}
    </button>
  );
}

export default Button;
