import React from "react";
import { Link } from "react-router-dom";

export default function SiteButton({
  buttonText,
  as: Component = "button",
  to,
  className: customClassName = "",
  ...props
}) {
  const defaultClassName =
    "bg-transparent border border-white border-opacity-100 text-white text-sm font-medium py-2 px-10 rounded-lg cursor-pointer transition-colors duration-300 w-full mx-auto m-1";

  const className = `${defaultClassName} ${customClassName}`.trim();

  if (Component === Link) { 
    return (
      <Link to={to} className="w-full">
        <button className={className} {...props}>
          {buttonText}
        </button>
      </Link>
    );
  }

  return (
    <Component className={className} {...props}>
      {buttonText}
    </Component>
  );
}
