import React, { useEffect, useRef } from "react";
import "animate.css";

const AnimatedText = ({
  text,
  className = "",
  animation = "fadeInUp",
  delay = 0,
}) => {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(`animate__${animation}`);
            entry.target.style.opacity = 1;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => observer.disconnect();
  }, [animation]);

  return (
    <h1
      ref={textRef}
      className={`animate__animated ${className}`}
      style={{
        opacity: 0,
        animationDelay: `${delay}s`,
      }}
    >
      {text.toUpperCase()}
    </h1>
  );
};

export default AnimatedText;
