import moment from 'moment'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function Ticket({ ticket, company, bgColor}) {
  return (
    <Card style={{backgroundColor: 'black'}} className={`${ticket?.scanid} ticket-card m-[50px]`}>
      {/* Banner */}
      <Card.Img variant="top" src={ticket?.event?.promo?.banner} alt="Banner" />

      {/* Centered QRCode */}


      {/* Ticket information */}
      <Card.Body>
        <Container className="my-3">
          <Row className="justify-content-center">
            <QRCodeSVG imageSettings={{
              // src: company.logoLight,
              height: 50,
              width: 50,
              excavate: false,
            }} value={ticket?.scanid} renderAs="svg" />
          </Row>
        </Container>
        <Row>
          <hr style={{ borderColor: '#f542e95e', borderStyle: 'dotted' }} />
          <Col>
            <div className="d-flex flex-column align-items-center justify-content-between text-white">
              <Card.Text className='text-white' style={{ fontFamily: 'inter', fontSize: '1.0rem', color: 'white !important' }}>{ticket?.event?.name}</Card.Text>
              <Card.Text className="text-white text-center" style={{ fontFamily: 'goldbold', fontSize: 18 }}>{ticket?.ticketDescription || ticket?.ticketInventory?.ticketName}</Card.Text>
              <Card.Text className="text-white" style={{ fontFamily: 'inter', fontSize: 18 }}><b>{ticket?.first_name} {ticket?.last_name}</b></Card.Text>
              <Card.Text>{moment(ticket?.event?.start_date).format('lll')}</Card.Text>
              <div className="spacer"></div>
              <Card.Text className="text-white" style={{ fontFamily: 'goldbold', fontSize: 18 }}>
                {ticket?.admitted ? 'USED!' : 'ADMIT ONE'}
              </Card.Text>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Ticket