import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import FLOORPLAN1 from "../assets/images/Main+Mezz+Green+2.png";
import FLOORPLAN2 from "../assets/images/Patio.png";
import FLOORPLAN3 from "../assets/images/Theater.png";
import { useNavigate } from "react-router-dom";

import LightGallery from "lightgallery/react";

// import styles
// import 'lightgallery/css/lightgallery.css';
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

import lgShare from "lightgallery/plugins/share";
import "lightgallery/css/lg-share.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import AnimatedText from "./rj-components/AnimatedText";
import AnimatedBlock from "./rj-components/AnimatedBlock";

function FloorPlan() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth <= 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row
      className={`bg-white ${
        isMobile ? "px-4 py-8" : isTablet ? "p-10" : "p-[80px]"
      } items-center justify-center mx-0`}
    >
      <Col md={10} className="bg-black p-5">
        <Row>
          <Col
            md={5}
            className="flex flex-col gap-4 items-center justify-center mb-10"
          >
            {/* <h1 className="text-7xl text-white">FLOORPLANS</h1> */}
            <AnimatedText
              text={"FLOORPLANS"}
              className={`text-3xl ${
                isTablet ? "md:text-4xl" : "lg:text-5xl"
              } xl:text-6xl 2xl:text-7xl text-white text-center`}
            />
            <AnimatedBlock
              children={
                <p className="text-white text-[16px] text-center">
                  Our venue is perfect for any occasion! With versatile spaces
                  and detailed floorplans, we make it easy to customize your
                  event. Contact us to bring your vision to life!
                </p>
              }
            />

            <AnimatedBlock
              children={
                <button
                  onClick={() => navigate("/contact")}
                  className=" bg-white text-black border-1 border-black px-[35px] w-fit py-[12px] font-medium text-sm rounded-[5px] flex items-center justify-center gap-4"
                >
                  Rent Space
                </button>
              }
            />
          </Col>
          <Col md={7}>
            <div className="grid-gallery mt-10">
              <LightGallery
                onInit={null}
                speed={500}
                plugins={[lgThumbnail, lgZoom, lgShare]}
                share={{
                  facebook: true,
                  twitter: true,
                  instagram: true,
                }}
                elementClassNames="grid grid-cols-4 gap-2"
              >
                <a className="col-span-2 flex justify-center" href={FLOORPLAN1}>
                  <img
                    alt="Main Rooms"
                    src={FLOORPLAN1}
                    className="object-cover w-full h-full xs:h-[300px]"
                  />
                </a>
                <a className="flex justify-center" href={FLOORPLAN2}>
                  <img
                    alt="Patio"
                    src={FLOORPLAN2}
                    className="object-cover w-full h-full xs:h-[300px]"
                  />
                </a>
                <a className="flex justify-center" href={FLOORPLAN3}>
                  <img
                    alt="Theater"
                    src={FLOORPLAN3}
                    className="object-cover w-full h-full xs:h-[300px]"
                  />
                </a>
              </LightGallery>
            </div>
            <AnimatedBlock
              children={
                <p className="text-white text-center font-bold">
                  click to zoom
                </p>
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FloorPlan;
