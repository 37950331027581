import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import useViewport from '../../hooks/useViewport';
import GlobalContext from '../../contexts/store';
import { Get } from '../../api/server';
import TicketSwiper from '../../components/TicketSwiper';
import Ticket from '../../components/Ticket';

export default function Conformation() {
  const { company } = useContext(GlobalContext);
  const { user, event } = useParams();
  const { width } = useViewport();
  const isMobile = width <= 768;
  const [ticketData, setTicketData] = useState([]);

  useEffect(() => {
    async function getCompanyData() {
      const response = await Get(`/tickets/getUserTickets/${company?._id}/${user}/${event}`);
      if (response.length > 0) {
        setTicketData(response);
      };
    }

    company?._id && getCompanyData()
  }, [company?._id, event, user]);

  return (
    <>
      <div className="spacer"></div>
      <Row className='py-[100px]'>
        <Col className="ml-auto mr-auto text-center">
          <h1 style={{ fontFamily: 'goldbold', fontSize: 65, marginTop: 60 }} className="title text-black">
            Purchased Tickets
          </h1>
        </Col>
        <Col md={12} className="ml-auto mr-auto text-center">
          <h2 style={{ fontSize: 16 }} className="title text-black">
            We emailed your receipt and link to get back to this page. Please check your spam folder if you do not see it.
          </h2>
        </Col>
      </Row>



      <Container>
        <Row>

          {isMobile ? (
            <>
              {ticketData.length > 0 ? <TicketSwiper events={ticketData} company={company} /> : null}
            </>
          ) : (
            <>
              {ticketData.length > 0 ? ticketData.map((ticket, index) => (
                <Col key={index} md={4}>
                  <Ticket ticket={ticket} company={company} bgColor={index % 2 === 0 ? '#1f2251' : '#1f2252'} />
                </Col>
              )) : null}
            </>
          )}
          {/* <Col className="ml-auto mr-auto text-center">
            <h1 style={{ fontFamily: 'mainFont', fontSize: 10, marginTop: 60 }} className="title text-white">
              If your email was mistyped, please contact us at <a href={`mailto:contact@715harrison.com`} style={{ color: '#e14eca' }}>Contact@715harrison.com</a><br />
              Note; Your ticket can be looked up at the door with your ID.
            </h1>
          </Col> */}
        </Row>
      </Container>

      <div className="spacer"></div>
      <div className="spacer"></div>
    </>
  )
}



