import React from 'react'

function DarkGradient() {
  return (
    <>
      <div className='absolute inset-0 z-[1]' style={{
        background: 'linear-gradient(to top, rgba(0,0,0,1) 0%, transparent 35%, transparent 75%, rgba(0,0,0,1) 100%)'
      }}></div>
      <div className='absolute inset-0 z-[1]' style={{
        background: 'linear-gradient(to right, rgba(0,0,0,1) 0%, transparent 35%, transparent 75%, rgba(0,0,0,1) 100%)'
      }}></div>
    </>
  )
}

export default DarkGradient
