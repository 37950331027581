import jQuery from 'jquery';
let route = '/api';

function Get (url) {
    return jQuery.getJSON(`${route}${url}`);
}

function Post (url, data) {
    if (typeof data === typeof {}) {
      data = JSON.stringify(data);
    }
    return jQuery.ajax({
      url : `${route}${url}`,
      type: 'POST',
      contentType: "application/json; charset=utf-8",
      Origin: "*",
      dataType: "json",
      data : data,
    });
}

function Put (url, data) {
    if (typeof data === typeof {}) {
      data = JSON.stringify(data);
    }
    return jQuery.ajax({
      url : `${route}${url}`,
      type: 'PUT',
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data : data
    });
  }

function Delete (url) {
    return jQuery.ajax({
      url : `${route}${url}`,
      type: 'DELETE'
    });
}

export { Get, Post, Put, Delete};
